<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"><i class="fas fa-tachometer-alt fa-fw"></i> Dashboard</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="#">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <!-- ./col -->
                    <div class="col-lg-3 col-6" v-if="this.$Gate.isAdmin()">
                        <!-- small box -->
                        <div v-if="childDataLoaded">
                            <DashboardCount :dashboardcount="dashboardCount.users_count" detailurl="/users"
                                            bgclass="small-box bg-info"
                                            ctitle="Registered Users" cicon="ion ion-person-add"></DashboardCount>
                        </div>
                    </div>
                    <!-- ./col -->

                    <!-- ./col -->
                    <div class="col-lg-3 col-6">
                        <!-- small box -->
                        <div v-if="childDataLoaded">
                            <DashboardCount :dashboardcount="dashboardCount.query_count" detailurl="/queries"
                                            bgclass="small-box bg-primary"
                                            ctitle="Queries" cicon="ion ion-android-car"></DashboardCount>
                        </div>
                    </div>
                    <!-- ./col -->

                    <!-- ./col -->
                    <div class="col-lg-3 col-6">
                        <!-- small box -->
                        <div v-if="childDataLoaded">
                            <DashboardCount :dashboardcount="dashboardCount.message_count" detailurl="/messages"
                                            bgclass="small-box bg-success"
                                            ctitle="Messages" cicon="ion ion-email"></DashboardCount>
                        </div>
                    </div>
                    <!-- ./col -->

                    <!-- ./col -->
                    <div class="col-lg-3 col-6">
                        <!-- small box -->
                        <div v-if="childDataLoaded">
                            <DashboardCount :dashboardcount="dashboardCount.free_call" detailurl="/free-calls"
                                            bgclass="small-box bg-purple"
                                            ctitle="Call Requests" cicon="ion ion-android-phone-portrait"></DashboardCount>
                        </div>
                    </div>
                    <!-- ./col -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardCount from '../components/dashboard/DashboardCount';

    export default {
        components: {DashboardCount,},
        data() {
            return {
                childDataLoaded: false,
                dashboardCount: {},
            }
        },
        methods: {
            loadAdminSummary() {
                if (this.$Gate.isAdmin()) {
                    this.$Progress.start();
                    axios.get('api/adminsummary').then(({data}) => (this.dashboardCount = data.data));
                    this.childDataLoaded = true;
                    this.$Progress.finish();
                }
            },
            loadManagerSummary() {
                if (this.$Gate.isManager()) {
                    this.$Progress.start();
                    axios.get('api/managersummary').then(({data}) => (this.dashboardCount = data.data));
                    this.childDataLoaded = true;
                    this.$Progress.finish();
                }
            },
        },
        created() {
            if (this.$Gate.isAdmin()) {
                this.loadAdminSummary();
            } else {
                this.loadManagerSummary();
            }
        }
    }
</script>
