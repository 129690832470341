<template>
    <div>
        <div v-if="displayData">
            <div v-if="$Gate.isAdmin()">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0 text-dark"><i class="fas fa-taxi fa-fw"></i> Add Segment</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item">
                                        <router-link to="/posts">Add Segment</router-link>
                                    </li>
                                    <li v-show="!editmode" class="breadcrumb-item active">Add New</li>
                                    <li v-show="editmode" class="breadcrumb-item active">Edit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">

                                <div class="card card-primary card-outline">
                                    <div class="card-header">
                                        <h3 v-show="!editmode" class="card-title"><i
                                            class="fas fa-copy fa-fw text-green"></i>
                                            Add Segment</h3>
                                        <h3 v-show="editmode" class="card-title"><i
                                            class="fas fa-taxi fa-fw text-green"></i>
                                            Edit Segment</h3>

                                        <div class="card-tools">

                                        </div>
                                    </div>
                                    <form role="form" @submit.prevent="!editmode ? createData() : editData()">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label for="title">Segment Name</label>
                                                <input v-model="form.segment_name" type="text" class="form-control"
                                                       name="title"
                                                       id="title"
                                                       placeholder="Enter Post Title"
                                                       :class="{ 'is-invalid': form.errors.has('segment_name') }">
                                                <has-error :form="form" field="segment_name"></has-error>
                                            </div>
                                            <div class="form-group">
                                                <label for="featuredImage">Featured Image</label>
                                                <div class="input-group">
                                                    <input type="file" @change="updateFeaturedImage"
                                                           class="form-control-file" id="featuredImage"/>
                                                </div>
                                                <div class="error" v-if="form.errors.has('image')">
                                                    {{form.errors.get('image')}}
                                                </div>
                                            </div>
                                        </div>

                                        <!-- /.card-body -->

                                        <div class="card-footer">
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                        </div>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <NotFound/>
            </div>
        </div>
        <div v-else>
            <NotFound/>
        </div>
    </div>
</template>

<script>
    import {VueEditor} from "vue2-editor";
    import NotFound from "../../components/NotFound";

    export default {
        components: {
            VueEditor,
            NotFound
        },
        data() {
            return {
                editmode: false,
                displayData: false,
                dataID: this.$route.params.id,
                form: new Form({
                    id: '',
                    segment_name: '',
                    image: '',
                    status: '',
                })
            };
        },
        methods: {
            getData() {
                let base_url = window.location.origin + '/api/cabSegments/';
                axios.get(base_url + this.dataID).then((response) => {
                    if (response.data.status == 200) {
                        this.displayData = true;
                        this.form.fill(response.data.data);
                    } else {
                        this.$router.push('/not-found');
                    }
                });
            }, updateFeaturedImage(e) {
                let extensions = ["image/png", "image/jpg", "image/jpeg", "image/JPEG", "image/JPG", "image/PNG"];
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (extensions.includes(file['type'])) {
                    if (file['size'] > limit) {
                        swal.fire('Error!', 'Image size should be less than 2MB !!', 'error',);
                        return false;
                    }

                } else {
                    swal.fire('Error!', 'Only is PNG, JPG and JPEG images are allowed !!', 'error',);
                    return false;
                }
                reader.readAsDataURL(file);
                reader.onloadend = (file) => {
                    let image = new Image();
                    image.src = file.target.result;
                    this.form.image = reader.result;
                }

            },
            createData() {
                this.$Progress.start();
                this.form.post('api/cabSegments')
                    .then(({data}) => {
                        Fire.$emit('AfterCreate');
                        toast.fire({
                            icon: 'success',
                            title: 'Segment Created Successfully'
                        });
                        this.$router.push('/cab-segments');
                        this.$Progress.finish();
                    }).catch((err) => {
                    this.$Progress.fail();
                    toast.fire({
                        icon: 'error',
                        title: 'Error Creating Segment'
                    });
                });

            }, editData() {
                let base_url = window.location.origin + '/api/cabSegments/';
                this.$Progress.start();
                this.form.put(base_url + this.form.id).then(() => {
                    swal.fire(
                        'Updated!',
                        'Segment was updated successfully.',
                        'success'
                    ).then((result) => {
                        toast.fire({
                            icon: 'success',
                            title: 'Segment Updated Successfully'
                        });
                        this.$router.push('/cab-segments');
                        this.$Progress.finish();
                    }).catch(() => {
                        this.$Progress.fail();
                        swal.fire("Failed!", "Something went wrong!!", "warning");
                    });
                    Fire.$emit('AfterCreate');
                }).catch(() => {
                    this.$Progress.fail();
                    swal.fire("Failed!", "Something went wrong!!", "warning");
                });
            },
        },
        created() {
            let params = this.$route.params;
            if (Object.keys(params).length > 0) {
                this.displayData = false;
                let id = this.$route.params.id;

                if (id !== '' || id !== null) {
                    this.editmode = true;
                    this.getData();
                }
            } else {
                this.displayData = true;
            }
        }
    }
</script>
<style scoped>
    .error {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #e3342f;
    }
</style>
