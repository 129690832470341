export default class Gate {

    constructor(user) {
        this.user = user;
    }

    isAdmin() {
        return this.user.type === "ADMIN";
    }

    isManager() {
        return this.user.type === "MANAGER";
    }

    isAuthor() {
        return this.user.type === "AUTHOR";
    }

    isEditor() {
        return this.user.type === "EDITOR";
    }

    isUser() {
        return this.user.type === "USER";
    }
}
