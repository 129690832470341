<style scoped>
    .action-btn {
        background: transparent;
        border: none;
    }

    #userViewModal .card-header {
        border-radius: 0 !important;
        max-height: 0px;
        padding: 0px;
        margin: 0px;
        border: none;
    }

    #userViewModal .btn-tool {
        cursor: pointer !important;
    }
</style>

<template>
    <div>
        <div v-if="$Gate.isAdmin()">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark"><i class="fas fa-users-cog fa-fw"></i> Users</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item">
                                    <router-link to="/dashboard">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active">Users</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <h3 class="card-title"><i class="fas fa-users fa-fw text-green"></i> Users</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-success" @click="newModal"><i
                                            class="fas fa-user-plus fa-fw"></i>
                                            Add
                                            User
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body table-responsive p-0">
                                    <table class="table table-hover">
                                        <thead class="text-center text-blue">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>User Type</th>
                                            <th>User Status</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody class="text-center">
                                        <tr v-if="loading">
                                            <td colspan="7">Loading...</td>
                                        </tr>
                                        <tr v-if="status == $root.statusNOK">
                                            <td colspan="7">{{message}}</td>
                                        </tr>
                                        <tr v-for="(user,index) in users.data" :key="user.id" v-else>
                                            <td>{{index+1}}</td>
                                            <td>{{user.name | upText}}</td>
                                            <td>{{user.email}}</td>
                                            <td>{{user.type}}</td>
                                            <td>{{user.status}}</td>
                                            <td>{{user.created_at | createDate}}</td>
                                            <td>
                                                <button class="action-btn" @click="viewModal(user)"><i
                                                    class="fas fa-eye fa-fw text-green"></i></button>
                                                | <a href="#"
                                                     @click="editModal(user)"><i
                                                class="fas fa-edit fa-fw text-orange"></i></a> | <a href="#"
                                                                                                    @click="deleteUser(user.id)"><i
                                                class="fas fa-trash fa-fw text-red"></i></a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer cus-pagination">
                                    <pagination :data="users" @pagination-change-page="getResults">
                                        <span slot="prev-nav">&lt; Previous</span>
                                        <span slot="next-nav">Next &gt;</span>
                                    </pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- User View Modal -->
            <div class="modal fade" id="userViewModal" tabindex="-1" role="dialog"
                 aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">

                    <div class="col-md-12">

                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                            <div class="card-header bg-info">

                                <div class="card-tools">
                                    <button type="button" data-dismiss="modal" class="btn btn-tool"><i
                                        class="fas fa-times-circle"></i>
                                    </button>
                                </div>
                                <!-- /.card-tools -->
                            </div>
                            <!-- Add the bg color to the header using any of the bg-* classes -->
                            <div class="widget-user-header bg-info">
                                <h3 class="widget-user-username">{{form.name}}</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" :src="getProfilePhoto()"
                                     alt="User Avatar">
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header">EMAIL</h5>
                                            <span class="description-text">{{form.email}}</span>
                                        </div>
                                        <!-- /.description-block -->
                                    </div>
                                    <!-- /.col -->
                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header">TYPE</h5>
                                            <span class="description-text">{{form.type}}</span>
                                        </div>
                                        <!-- /.description-block -->
                                    </div>
                                    <!-- /.col -->
                                    <div class="col-sm-4">
                                        <div class="description-block">
                                            <h5 class="description-header">STATUS</h5>
                                            <span class="description-text">{{form.status}}</span>
                                        </div>
                                        <!-- /.description-block -->
                                    </div>
                                    <!-- /.col -->
                                </div>
                                <!-- /.row -->
                            </div>
                        </div>
                        <!-- /.widget-user -->
                    </div>


                </div>
            </div>

            <!-- Add/Edit User Modal -->
            <div class="modal fade" id="addNew" data-backdrop="static" tabindex="-1" role="dialog"
                 aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 v-show="!editmode" class="modal-title" id="staticBackdropLabel"><i
                                class="fas fa-user-plus fa-fw"></i> Add New
                                User</h5>
                            <h5 v-show="editmode" class="modal-title" id="staticBackdropLabelEdit"><i
                                class="fas fa-user-edit fa-fw"></i> Update
                                User Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="editmode ? updateUser() : createUser()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <input v-model="form.name" type="text" name="name" placeholder="Name"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                                    <has-error :form="form" field="name"></has-error>
                                </div>
                                <div class="form-group">
                                    <input v-model="form.email" type="text" name="email" placeholder="Email"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                    <has-error :form="form" field="email"></has-error>
                                </div>
                                <div class="form-group">
                                    <input v-model="form.password" type="password" name="password"
                                           placeholder="Password"
                                           class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
                                    <has-error :form="form" field="password"></has-error>
                                </div>
                                <div class="form-group">
                                    <input v-show="!editmode" v-model="form.password_confirmation" type="password"
                                           name="password_confirmation"
                                           placeholder="Confirm Password"
                                           class="form-control"
                                           :class="{ 'is-invalid': form.errors.has('password_confirmation') }">
                                    <has-error :form="form" field="password_confirmation"></has-error>
                                </div>
                                <div class="form-group">
                                    <select v-model="form.type" type="password" name="type"
                                            class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                        <option value="" selected="true" disabled="disabled">--Select User Role--
                                        </option>
                                        <option value="USER">User</option>
                                    </select>
                                    <has-error :form="form" field="type"></has-error>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button v-show="!editmode" type="submit" class="btn btn-success"><i
                                    class="fas fa-save fa-fw"></i> Create
                                </button>
                                <button v-show="editmode" type="submit" class="btn btn-primary"><i
                                    class="fas fa-edit fa-fw"></i> Update
                                </button>
                                <button type="button" class="btn btn-danger" data-dismiss="modal"><i
                                    class="fa fa-times-circle fa-fw"></i> Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <not-found></not-found>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                editmode: false,
                users: {},
                loading: true,
                status: '',
                message: '',
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    type: '',
                    profile_image: '',
                    status: ''
                })
            }
        },
        methods: {
            getProfilePhoto() {
                let profile_image;
                profile_image = (this.form.profile_image.length > 200) ? this.form.profile_image : this.form.profile_image;
                return profile_image;
            },
            newModal() {
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            viewModal(user) {
                $('#userViewModal').modal('show');
                this.form.fill(user);
            },
            editModal(user) {
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            updateUser() {
                this.$Progress.start();
                this.form.put('api/users/' + this.form.id).then(() => {
                    $('#addNew').modal('hide');
                    swal.fire(
                        'Updated!',
                        'User info was updated successfully.',
                        'success'
                    )
                    this.$Progress.finish();
                    Fire.$emit('AfterCreate');

                }).catch(() => {
                    this.$Progress.fail();
                    swal.fire("Failed!", "Something went wrong!!", "warning");
                });
            },
            deleteUser(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.form.delete('api/users/' + id).then(() => {
                            swal.fire(
                                'Deleted!',
                                'User was deleted successfully.',
                                'success'
                            )
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    //console.log(error);
                })
            },
            getResults(page = 1) {
                axios.get('api/normalUsers?page=' + page)
                    .then(response => {
                        this.users = response.data;
                    });
            },
            loadUsers() {
                if (this.$Gate.isAdmin()) {
                    this.$Progress.start();
                    axios.get('api/normalUsers').then(({data}) => {
                        this.loading = false;
                        if (data.status == this.$root.statusOK) {
                            this.status = data.status;
                            this.users = data;
                        } else {
                            this.status = data.status;
                            this.message = data.message;
                        }
                    });
                    this.$Progress.finish();
                }
            },
            createUser() {
                this.$Progress.start();
                this.form.post('api/users')
                    .then(({data}) => {
                        Fire.$emit('AfterCreate');
                        $('#addNew').modal('hide');
                        toast.fire({
                            icon: 'success',
                            title: 'User Created Successfully'
                        });
                        this.$Progress.finish();
                    }).catch(() => {

                });

            }
        },
        created() {
            Fire.$on('searching', () => {
                this.loading = true;
                let query = this.$parent.$parent.search;
                axios.get('api/findNormalUser?q=' + query).then(({data}) => {
                    this.$Progress.start();
                    this.loading = false;
                    if (data.status == this.$root.statusOK) {
                        this.users = data;
                        this.status = data.status;
                    } else {
                        this.message = data.message;
                        this.status = data.status;
                    }
                    this.$Progress.finish();
                }).catch((e) => {
                    this.$Progress.fail();
                    swal.fire("Failed!", "Something went wrong, try again later!!", "warning");
                });
            });
            this.loadUsers();
            Fire.$on('AfterCreate', () => {
                this.loadUsers();
            });
        }
    }
</script>
