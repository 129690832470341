import Vue from 'vue';
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard";
import Pages from "../views/pages/Pages";
import AddEditPage from "../views/pages/AddEditPage";
import ViewPage from "../views/pages/ViewPage";
import Posts from "../views/posts/Posts";
import AddEditPost from "../views/posts/AddEditPost";
import ViewPost from "../views/posts/ViewPost";
import Profile from "../views/Profile";
import Users from "../views/users/Users";
import ContactMessages from "../views/ContactMessages";
import NotFound from "../components/NotFound";
import Drivers from "../views/users/Drivers";
import StaffMembers from "../views/users/StaffMembers";
import AddEditDriver from "../views/users/AddEditDriver";
import CabSegments from "../views/cabSegments/CabSegments";
import AddEditCabSegments from "../views/cabSegments/AddEditCabSegments";
import Index from "../views/queries/Index";
import ViewSegment from "../views/cabSegments/ViewSegment";
import FreeCalls from "../views/freecalls/FreeCalls";

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        {path: '/dashboard', name: 'dashboard', component: Dashboard},
        {path: '/pages', name: 'page.index', component: Pages},
        {path: '/add-page', name: 'page.add', component: AddEditPage},
        {path: '/edit-page/:id', name: 'page.edit', component: AddEditPage},
        {path: '/page/:id', name: 'page.view', component: ViewPage},
        {path: '/posts', name: 'post.index', component: Posts},
        {path: '/add-post', name: 'post.add', component: AddEditPost},
        {path: '/edit-post/:id', name: 'post.edit', component: AddEditPost},
        {path: '/post/:id', name: 'post.view', component: ViewPost},
        {path: '/profile', name: 'profile', component: Profile},
        {path: '/users', name: 'user.index', component: Users},
        {path: '/drivers', name: 'driver.index', component: Drivers},
        {path: '/edit-driver/:id', name: 'driver.edit', component: AddEditDriver},
        {path: '/add-driver', name: 'driver.add', component: AddEditDriver},
        {path: '/view-driver/:id', name: 'driver.view', component: AddEditDriver},
        {path: '/staff-members', name: 'staff-members.index', component: StaffMembers},
        {path: '/cab-segments', name: 'cab-segments.index', component: CabSegments},
        {path: '/add-cab-segments', name: 'cab-segments.add', component: AddEditCabSegments},
        {path: '/edit-cab-segments/:id', name: 'cab-segments.edit', component: AddEditCabSegments},
        {path: '/view-cab-segments/:id', name: 'cab-segments.view', component: ViewSegment},
        {path: '/queries', name: 'queries.index', component: Index},
        {path: '/messages', name: 'message.index', component: ContactMessages},
        {path: '/free-calls', name: 'free-calls.index', component: FreeCalls},
        {path: '*', component: NotFound},
    ],
    mode: 'history'
});
