<template>
    <div>
        <div v-if="status == $root.statusOK">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark"><i class="fas fa-copy fa-fw"></i> Posts</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item">
                                    <router-link to="/posts">Posts</router-link>
                                </li>
                                <li class="breadcrumb-item active">Post Details</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <h3 class="card-title"><i class="fas fa-copy fa-fw text-green"></i>
                                        Post Details</h3>
                                    <div class="card-tools">

                                    </div>
                                </div>
                                <form>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Title</label>
                                            <p>{{post.title}}</p>
                                            <hr>
                                        </div>
                                        <div class="form-group">
                                            <label>Slug</label>
                                            <p>{{post.slug}}</p>
                                            <hr>
                                        </div>
                                        <div class="form-group">
                                            <label>Excerpt</label>
                                            <p>{{post.excerpt}}</p>
                                            <hr>
                                        </div>

                                        <div class="form-group">
                                            <label>Body</label>
                                            <p>{{post.body | stripTags}}</p>
                                            <hr>
                                        </div>

                                        <div class="form-group">
                                            <label>Featured Image</label>
                                            <p>

                                                <img
                                                    v-if="post.image != '' || post.image != null || this.post.image != undefined"
                                                    class="img-fluid elevation-2"
                                                    :src="getFeaturedImage()"
                                                    alt="Featured Image">
                                                <img v-else class="img-circle img-fluid elevation-2" width="50"
                                                     height="50"
                                                     src="/public/img/utilities/loading.gif"
                                                     alt="Loading">
                                            </p>
                                            <hr>
                                        </div>
                                        <div class="form-group">
                                            <label>Alt Image Title</label>
                                            <p>{{post.alt_image_tag | emptyReplace}}</p>
                                            <hr>
                                        </div>
                                        <div class="form-group">
                                            <label>SEO Title</label>
                                            <p>{{post.seo_title | emptyReplace}}</p>
                                            <hr>
                                        </div>
                                        <div class="form-group">
                                            <label>Meta Description</label>
                                            <p>{{post.meta_description | emptyReplace}}</p>
                                            <hr>
                                        </div>
                                        <div class="form-group">
                                            <label>Meta Keywords</label>
                                            <p>{{post.meta_keywords | emptyReplace}}</p>
                                            <hr>
                                        </div>

                                    </div>

                                    <!-- /.card-body -->
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <not-found/>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                postID: this.$route.params.id,
                post: {},
                message: "",
                status: this.$root.statusOK
            };
        },
        methods: {
            getPost() {
                let base_url = window.location.origin + '/api/posts/';
                //axios.get(base_url + this.postID).then(({data}) => (this.post = data.data));
                axios.get(base_url + this.postID).then(({data}) => {
                    if (data.status == this.$parent.$parent.statusOK) {
                        this.post = data.data;
                    } else {
                        this.status = data.status;
                        this.message = data.message;
                    }
                });
            },
            getFeaturedImage() {
                let featured_image;
                featured_image = (this.post.image != '' || this.post.image != null || this.post.image != undefined) ? this.post.image : "";
                return featured_image;
            }
        },
        created() {
            let params = this.$route.params;
            if (Object.keys(params).length > 0) {
                let id = this.$route.params.id;
                if (id !== '' || id !== null) {
                    this.getPost();
                }
            }
        }
    }
</script>
