<template>
    <div :class="bgclass">
        <div class="inner">
            <h3>{{ dashboardcount }}</h3>

            <p>{{ ctitle }}</p>
        </div>
        <div class="icon">
            <i :class="cicon"></i>
        </div>
        <router-link :to="detailurl" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i>
        </router-link>
    </div>
</template>

<script>
    export default {
        props: ['dashboardcount', 'detailurl', 'bgclass', 'ctitle', 'cicon'],
        data() {
            return {
                dashboardCount: this.dashboardcount,
            }
        },
        mounted() {
            //
        }
    }
</script>
