<style scoped>
    .router-link-exact-active {
        background-color: transparent !important;
        color: #ffffff !important;
    }
</style>

<template>
    <div>
        <div v-if="Object.keys(appSetting).length > 0 && appSetting.constructor === Object">
            <router-link to="/dashboard" class="brand-link">
                <img :src="getAppLogo()" alt="App Logo" class="brand-image img-circle elevation-3"
                     style="opacity:0.8">
                <span class="brand-text font-weight-light">{{getCompanyName()}}</span>
            </router-link>
        </div>
        <div v-else>
            <router-link to="/dashboard" class="brand-link">
                <img src="/public/img/utilities/loading.gif" alt="App Logo" class="brand-image img-circle elevation-3"
                     style="opacity:0.8">
                <span class="brand-text font-weight-light">Loading...</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                appSetting: {}
            }
        },
        methods: {
            getAppLogo() {
                let logo;
                logo = window.location.origin + "/public/" + this.appSetting.app_logo;
                return logo;
            },
            getCompanyName() {
                if (Object.keys(this.appSetting).length > 0 && this.appSetting.constructor === Object) {
                    let name, nameLength;
                    name = this.appSetting.app_name;
                    nameLength = this.appSetting.app_name.length;
                    if (nameLength > 12) {
                        let split_names = name.trim().split(" ");
                        if (split_names.length > 1) {
                            name = (split_names[0] + " " + split_names[1].charAt(0) + ".");
                        }
                    }
                    return name;
                }
            }
        },
        created() {
            let base_url = window.location.origin;
            axios.get(base_url + '/api/appSetting').then((data) => {
                this.appSetting = data.data;
            });
        }
    }
</script>
