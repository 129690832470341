<template>
    <div>
        <div v-if="$Gate.isAdmin()">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark"><i class="fas fa-copy fa-fw"></i> Pages</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item">
                                    <router-link to="/dashboard">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active">Pages</li>
                            </ol>
                        </div>
                    </div>
                    <div class="row text-center" v-if="loading">
                        <div class="col-12 ml-auto mr-auto">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <h3 class="card-title"><i class="fas fa-copy fa-fw text-green"></i> Pages</h3>

                                    <div class="card-tools">
                                        <router-link to="/add-page" class="btn btn-success"><i
                                            class="fas fa-plus-circle fa-fw"></i>
                                            Add
                                            Page
                                        </router-link>
                                    </div>
                                </div>
                                <div class="card-body table-responsive p-0">
                                    <table class="table table-hover">
                                        <thead class="text-center text-blue">
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Title</th>
                                            <th>Page Type</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody class="text-center">
                                        <tr v-if="status == $root.statusNOK">
                                            <td colspan="5">{{message}}</td>
                                        </tr>
                                        <tr v-for="(post,index) in posts.data" :key="post.id" v-else>
                                            <td>{{index+1}}</td>
                                            <td>{{post.title | upText}}</td>
                                            <td>{{post.page_type}}</td>
                                            <td>{{post.created_at | createDate}}</td>
                                            <td>
                                                <router-link :to="'/page/' + post.id" v-tooltip:top="'View Page'"><i
                                                    class="fas fa-eye fa-fw text-green"></i></router-link>
                                                |
                                                <router-link :to="'/edit-page/' + post.id" v-tooltip:top="'Edit Page'"><i
                                                    class="fas fa-edit fa-fw text-orange"></i></router-link>
                                                | <a href="javascript:void(0)" @click="deletePost(post.id)" v-tooltip:top="'Delete Page'"><i
                                                class="fas fa-trash fa-fw text-red"></i></a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer cus-pagination">
                                    <pagination :data="posts" @pagination-change-page="getResults">
                                        <span slot="prev-nav">&lt; Previous</span>
                                        <span slot="next-nav">Next &gt;</span>
                                    </pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else>
            <not-found></not-found>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                posts: {},
                status: "",
                message: "",
                loading: true
            }
        },
        methods: {
            deletePost(id) {

                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        axios.delete('api/pages/' + id).then(() => {
                            swal.fire(
                                'Deleted!',
                                'Post was deleted successfully.',
                                'success'
                            )
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    //console.log(error);
                })
            },
            getResults(page = 1) {
                axios.get('api/pages?page=' + page)
                    .then(response => {
                        this.posts = response.data;
                    });
            },
            loadPosts() {
                if (this.$Gate.isAdmin()) {
                    this.$Progress.start();
                    axios.get('api/pages').then(({data}) => {
                        this.loading = false;
                        if (data.status == this.$root.statusOK) {
                            this.posts = data;
                            this.status = data.status;
                        } else {
                            this.status = data.status;
                            this.message = data.message;
                        }
                    });
                    this.$Progress.finish();
                }
            },
        },
        created() {
            Fire.$on('searching', () => {
                let query = this.$root.search;
                this.loading = true;
                axios.get('api/findPage?q=' + query).then(({data}) => {
                    this.$Progress.start();
                    this.loading = false;
                    if (data.status == this.$root.statusOK) {
                        this.posts = data;
                        this.status = data.status;
                    } else {
                        this.status = data.status;
                        this.message = data.message;
                    }
                    this.$Progress.finish();
                }).catch(() => {
                    this.$Progress.fail();
                    this.loading = false;
                    swal.fire("Failed!", "Something went wrong, try again later!!", "warning");
                });
            });
            this.loadPosts();
            Fire.$on('AfterCreate', () => {
                this.loadPosts();
            });
        }
    }
</script>
