<style scoped>
    .action-btn {
        background: transparent;
        border: none;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .action-border {
        border-right: 1.5px solid black;
    }
</style>
<template>
    <div>
        <div v-if="$Gate.isAdmin() || $Gate.isManager()">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark"><i class="fas fa-envelope fa-fw"></i> Messages</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item">
                                    <router-link to="/dashboard">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active">Messages</li>
                            </ol>
                        </div>
                    </div>
                    <div class="row text-center" v-if="loading">
                        <div class="col-12 ml-auto mr-auto">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="card card-primary card-outline">

                                <div class="card-header">
                                    <h3 class="card-title"><i class="fas fa-envelope fa-fw text-orange"></i> Messages
                                    </h3>

                                    <div class="card-tools">

                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div v-if="status == $root.statusOK">
                                    <div class="card-body table-responsive p-0">
                                        <table class="table table-hover text-center">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(message, index) in messages.data" :key="message.id">
                                                <td>{{index+1}}</td>
                                                <td>{{message.name}}</td>
                                                <td>{{message.email}}</td>
                                                <td>{{message.phone}}</td>
                                                <td><b :class="classQueryStatus(message.status)">{{message.status}}</b>
                                                </td>
                                                <td>
                                                    <button class="action-btn action-border"
                                                            @click="viewModal(message)"><i
                                                        class="fas fa-eye fa-fw text-purple" v-tooltip:top="'View Message'"></i></button>
                                                    <button class="action-btn action-border"
                                                            @click="updateData(message.id)"
                                                            v-if="message.status == 'OPEN'" v-tooltip:top="'Mark Complete'"><i
                                                        class="fas fa-check-circle fa-fw text-green"></i></button>
                                                    <button class="action-btn" @click="deleteMessage(message.id)">
                                                        <i class="fas fa-trash fa-fw text-red" v-tooltip:top="'Delete Request'"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="card-footer cus-pagination">
                                        <pagination :data="messages" @pagination-change-page="getResults">
                                            <span slot="prev-nav">&lt; Previous</span>
                                            <span slot="next-nav">Next &gt;</span>
                                        </pagination>
                                    </div>
                                </div>
                                <div v-else class="card-body text-center">
                                    <p class="lead">{{message}}</p>
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>


            <!-- View Modal -->
            <div class="modal fade" id="viewMessage" data-backdrop="static" tabindex="-1" role="dialog"
                 aria-labelledby="modalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalLongTitle"><i class="fas fa-list fa-fw"></i> Message
                                Details
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <label class="col-md-4">Name</label>
                                <div class="col-md-6">
                                    <p>{{form.name}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Email</label>
                                <div class="col-md-6">
                                    <p>{{form.email}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Phone</label>
                                <div class="col-md-6">
                                    <p>{{form.phone}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Subject</label>
                                <div class="col-md-6">
                                    <p>{{form.subject}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4">Status</label>
                                <div class="col-md-6">
                                    <p :class="classQueryStatus(form.status)">{{form.status}}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <label>Message</label>
                                    <p class="text-justify">{{form.message}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div v-else>
        <not-found/>
    </div>
    </div>
</template>

<script>
    import styleMixins from "../mixins/styleMixins";

    export default {
        data() {
            return {
                messages: {},
                status: '',
                loading: true,
                message: '',
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                    status: ''
                })
            }
        },
        methods: {
            getResults(page = 1) {
                axios.get('api/contactmessages?page=' + page)
                    .then(response => {
                        this.messages = response.data;
                    });
            },
            loadMessages() {
                if (this.$Gate.isAdmin() || this.$Gate.isManager()) {
                    this.$Progress.start();
                    axios.get('api/contactmessages').then(({data}) => {
                        if (data.status == this.$root.statusOK) {
                            this.status = data.status;
                            this.messages = data;
                            this.loading = false;
                        } else {
                            this.status = data.status;
                            this.message = data.message;
                            this.loading = false;
                        }
                    });
                    this.$Progress.finish();
                }
            },
            viewModal(message) {
                $('#viewMessage').modal('show');
                this.form.fill(message);
            },
            deleteMessage(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.form.delete('api/contactmessages/' + id).then(() => {
                            swal.fire(
                                'Deleted!',
                                'Message was deleted successfully.',
                                'success'
                            )
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    //console.log(error);
                })
            },
            updateData(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, close it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.form.put('api/contactmessages/' + id).then(() => {
                            swal.fire(
                                'Updated!',
                                'Status was updated successfully.',
                                'success'
                            )
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    //console.log(error);
                })
            },
        },
        mixins: [
            styleMixins
        ],
        created() {
            Fire.$on('searching', () => {
                let query = this.$root.search;
                this.loading = true;
                axios.get('api/findContactMessage?q=' + query).then(({data}) => {
                    this.$Progress.start();
                    if (data.status == this.$root.statusOK) {
                        this.status = data.status;
                        this.messages = data
                        this.loading = false;
                    } else {
                        this.status = data.status;
                        this.message = data.message;
                        this.loading = false;
                    }
                    this.$Progress.finish();
                }).catch(() => {
                    this.loading = false;
                    this.$Progress.fail();
                    swal.fire("Failed!", "Something went wrong, try again later!!", "warning");
                });
            });
            this.loadMessages();
            Fire.$on('AfterCreate', () => {
                this.loadMessages();
            });
        }
    }
</script>
