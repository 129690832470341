<template>
    <li class="nav-item" @click="markNotificationAsRead">
        <router-link to="/messages" class="nav-link">
            <i class="nav-icon fas fa-envelope"></i>
            <p>
                Messages
                <span class="badge badge-success right">{{unreadNotifications.length}}</span>
            </p>
        </router-link>
    </li>
</template>

<script>
    export default {
        props: ['unreads', 'userid'],
        data() {
            return {
                unreadNotifications: this.unreads
            }
        },
        methods: {
            markNotificationAsRead() {
                if (this.unreadNotifications.length) {
                    axios.get('api/markAsRead').then(response=>{
                        this.unreadNotifications = [];
                    });
                }
            }
        },
        mounted() {
            Echo.private('App.User.' + this.userid)
                .notification((notification) => {
                    let newUnreadNotifications = {data: {thread: notification.thread, user: notification.user}};
                    this.unreadNotifications.push(newUnreadNotifications);
                });
        }
    }
</script>
