/* Import Statements */
require('./bootstrap');
import moment from 'moment';
import {Form, HasError, AlertError} from 'vform';
import Gate from './core/Gate';
import router from "./core/router";
import VueProgressBar from 'vue-progressbar';
import swal from 'sweetalert2';

/* Global Window Objecsts */

window.Vue = require('vue');
window.Form = Form;
window.swal = swal;
window.toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
});
window.Fire = new Vue();

/* Vue Components */

Vue.prototype.$Gate = new Gate(window.user);
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);


Vue.component('app', require('./components/App').default);
Vue.component('not-found', require('./components/NotFound.vue').default);
Vue.component('app-detail', require('./components/AppDetailComponent.vue').default);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('cm-notification', require('./components/ContactMessageNotification').default);

Vue.directive('tooltip', function(el, binding){
    $(el).tooltip({
        title: binding.value,
        placement: binding.arg,
        trigger: 'hover'
    })
});

/* Vue Progressbar */

Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '2px'
})


/* Toaster */

const toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', swal.stopTimer)
        toast.addEventListener('mouseleave', swal.resumeTimer)
    }
})


/* Vue Filters */

Vue.filter('upText', function (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
});

Vue.filter('createDate', function (created) {
    return moment(created).format('MMMM Do YYYY');
});

Vue.filter('stripTags', function (content) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = content;
    return tmp.textContent || tmp.innerText || "";
});

Vue.filter('emptyReplace', function (content) {
    if (content == "" || content == null || content == undefined) {
        return "-";
    } else {
        return content;
    }
});

/* Create New View App */

const app = new Vue({
    el: '#app',
    router,
    data: {
        search: '',
        statusOK: 200,
        statusNOK: 400,
    },
    methods: {
        searchit: _.debounce(() => {
            Fire.$emit('searching');
        }, 1000)
    }
});
