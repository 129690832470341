<template>
    <div>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 mt-3">

                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                            <!-- Add the bg color to the header using any of the bg-* classes -->
                            <div class="widget-user-header bg-info">
                                <h3 class="widget-user-username">{{this.form.name}}</h3>
                                <h5 class="widget-user-desc">{{this.form.bio}}</h5>
                            </div>
                            <div class="widget-user-image">
                                <img v-if="form.profile_image.length > 0" class="img-circle elevation-2" :src="getProfilePhoto()"
                                     alt="User Avatar">
                                <img v-else class="img-circle elevation-2" src="/public/img/utilities/loading.gif"
                                     alt="Loading">
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header">Email</h5>
                                            <span class="description-text">{{this.form.email}}</span>
                                        </div>
                                        <!-- /.description-block -->
                                    </div>
                                    <!-- /.col -->
                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header">User Type</h5>
                                            <span class="description-text">{{this.form.type}}</span>
                                        </div>
                                        <!-- /.description-block -->
                                    </div>
                                    <!-- /.col -->
                                    <div class="col-sm-4">
                                        <div class="description-block">
                                            <h5 class="description-header">Status</h5>
                                            <span class="description-text">{{this.form.status}}</span>
                                        </div>
                                        <!-- /.description-block -->
                                    </div>
                                    <!-- /.col -->
                                </div>
                                <!-- /.row -->
                            </div>
                        </div>
                        <!-- /.widget-user -->

                        <div class="card">
                            <div class="card-header p-2">
                                <ul class="nav nav-pills">
                                    <li class="nav-item"><a class="nav-link active" href="#settings" data-toggle="tab">Profile
                                        Settings</a>
                                    </li>
                                    <li v-if="$Gate.isAdmin()" class="nav-item"><a class="nav-link" href="#appsetting"
                                                                                   data-toggle="tab">App Settings</a>
                                    </li>
                                </ul>
                            </div><!-- /.card-header -->
                            <div class="card-body">
                                <div class="tab-content">

                                    <!-- /.tab-pane -->
                                    <div class="tab-pane active" id="settings">
                                        <form class="form-horizontal">
                                            <div class="form-group row">
                                                <label for="inputName" class="col-sm-2 col-form-label">Name</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.name" type="text" class="form-control"
                                                           id="inputName" name="name"
                                                           placeholder="Name"
                                                           :class="{ 'is-invalid': form.errors.has('name') }">
                                                    <has-error :form="form" field="name"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.email" type="email" class="form-control"
                                                           id="inputEmail" name="email"
                                                           placeholder="Email"
                                                           :class="{ 'is-invalid': form.errors.has('email') }">
                                                    <has-error :form="form" field="email"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputFile" class="col-sm-2 col-form-label">Profile
                                                    Image</label>
                                                <div class="col-sm-10">
                                                    <input type="file" name="profile_image" @change="updateProfile"
                                                           id="inputFile">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputPhone" class="col-sm-2 col-form-label">Phone</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.phone" type="tel" class="form-control"
                                                           id="inputPhone" name="phone"
                                                           placeholder="Phone"
                                                           :class="{ 'is-invalid': form.errors.has('phone') }">
                                                    <has-error :form="form" field="phone"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputAddress"
                                                       class="col-sm-2 col-form-label">Address</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.address" type="text" class="form-control"
                                                           id="inputAddress" name="address"
                                                           placeholder="Address"
                                                           :class="{ 'is-invalid': form.errors.has('address') }">
                                                    <has-error :form="form" field="address"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputCity"
                                                       class="col-sm-2 col-form-label">City</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.city" type="text" class="form-control"
                                                           id="inputCity" name="city"
                                                           placeholder="City"
                                                           :class="{ 'is-invalid': form.errors.has('city') }">
                                                    <has-error :form="form" field="city"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputState"
                                                       class="col-sm-2 col-form-label">State</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.state" type="text" class="form-control"
                                                           id="inputState" name="state"
                                                           placeholder="State"
                                                           :class="{ 'is-invalid': form.errors.has('state') }">
                                                    <has-error :form="form" field="state"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputCountry"
                                                       class="col-sm-2 col-form-label">Country</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.country" type="text" class="form-control"
                                                           id="inputCountry" name="country"
                                                           placeholder="Country"
                                                           :class="{ 'is-invalid': form.errors.has('country') }">
                                                    <has-error :form="form" field="country"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputZipcode"
                                                       class="col-sm-2 col-form-label">Zipcode</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.zipcode" type="number" class="form-control"
                                                           id="inputZipcode" name="zipcode"
                                                           placeholder="Zipcode"
                                                           :class="{ 'is-invalid': form.errors.has('zipcode') }">
                                                    <has-error :form="form" field="zipcode"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputBio"
                                                       class="col-sm-2 col-form-label">Bio</label>
                                                <div class="col-sm-10">
                                                    <textarea v-model="form.bio" class="form-control"
                                                              id="inputBio" name="bio"
                                                              placeholder="Enter some information about yourself.."
                                                              :class="{ 'is-invalid': form.errors.has('bio') }"></textarea>
                                                    <has-error :form="form" field="bio"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="password" class="col-sm-2 col-form-label">Password</label>
                                                <div class="col-sm-10">
                                                    <input v-model="form.password" type="password" class="form-control"
                                                           id="password" name="password"
                                                           placeholder="Password"
                                                           :class="{ 'is-invalid': form.errors.has('password') }">
                                                    <has-error :form="form" field="password"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="offset-sm-2 col-sm-10">
                                                    <button type="submit" @click.prevent="updateInfo"
                                                            class="btn btn-success">Update
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <!-- /.tab-pane -->

                                    <div v-if="$Gate.isAdmin()" class="tab-pane" id="appsetting">
                                        <form class="form-horizontal">
                                            <div class="form-group row">
                                                <label for="appName" class="col-sm-2 col-form-label">App Name</label>
                                                <div class="col-sm-10">
                                                    <input v-model="appFrom.app_name" type="text" class="form-control"
                                                           id="appName" name="app_name"
                                                           placeholder="App Name"
                                                           :class="{ 'is-invalid': appFrom.errors.has('app_name') }">
                                                    <has-error :form="appFrom" field="app_name"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="appLogoFile" class="col-sm-2 col-form-label">App
                                                    Logo</label>
                                                <div class="col-sm-10">
                                                    <input type="file" name="app_logo" @change="updateLogo"
                                                           id="appLogoFile">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="companyName" class="col-sm-2 col-form-label">Company
                                                    Name</label>
                                                <div class="col-sm-10">
                                                    <input v-model="appFrom.company_name" type="text"
                                                           class="form-control"
                                                           id="companyName" name="company_name"
                                                           placeholder="Company Name"
                                                           :class="{ 'is-invalid': appFrom.errors.has('company_name') }">
                                                    <has-error :form="appFrom" field="company_name"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="companyEmail" class="col-sm-2 col-form-label">Company
                                                    Email</label>
                                                <div class="col-sm-10">
                                                    <input v-model="appFrom.company_email" type="email"
                                                           class="form-control"
                                                           id="companyEmail" name="company_email"
                                                           placeholder="Company Email"
                                                           :class="{ 'is-invalid': appFrom.errors.has('company_email') }">
                                                    <has-error :form="appFrom" field="company_email"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="companyPhone" class="col-sm-2 col-form-label">Company
                                                    Phone</label>
                                                <div class="col-sm-10">
                                                    <input v-model="appFrom.company_phone" type="tel"
                                                           class="form-control"
                                                           id="companyPhone" name="company_phone"
                                                           placeholder="Company Phone"
                                                           :class="{ 'is-invalid': appFrom.errors.has('company_phone') }">
                                                    <has-error :form="appFrom" field="company_phone"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="companyAltPhone" class="col-sm-2 col-form-label">Company
                                                    Alt Phone</label>
                                                <div class="col-sm-10">
                                                    <input v-model="appFrom.company_alt_phone" type="tel"
                                                           class="form-control"
                                                           id="companyAltPhone" name="company_alt_phone"
                                                           placeholder="Company Alt Phone"
                                                           :class="{ 'is-invalid': appFrom.errors.has('company_alt_phone') }">
                                                    <has-error :form="appFrom" field="company_alt_phone"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="companyAddress" class="col-sm-2 col-form-label">Company
                                                    Address</label>
                                                <div class="col-sm-10">
                                                    <textarea v-model="appFrom.company_address"
                                                              class="form-control"
                                                              id="companyAddress" name="company_address"
                                                              placeholder="Company Address"
                                                              :class="{ 'is-invalid': appFrom.errors.has('company_address') }"></textarea>
                                                    <has-error :form="appFrom" field="company_address"></has-error>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="offset-sm-2 col-sm-10">
                                                    <button type="submit" @click.prevent="updateAppInfo"
                                                            class="btn btn-success">Update
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <!-- /.tab-pane -->
                                </div>
                                <!-- /.tab-content -->
                            </div><!-- /.card-body -->
                        </div>
                        <!-- /.nav-tabs-custom -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    password: '',
                    type: '',
                    profile_image: '',
                    status: '',
                    phone: '',
                    bio: '',
                    address: '',
                    city: '',
                    state: '',
                    country: '',
                    zipcode: ''
                }),

                appFrom: new Form({
                    id: '',
                    app_name: '',
                    app_logo: '',
                    company_name: '',
                    company_phone: '',
                    company_alt_phone: '',
                    company_email: '',
                    company_address: ''
                })
            }
        },
        methods: {
            getProfilePhoto() {
                let profile_image;
                profile_image = (this.form.profile_image.length > 200) ? this.form.profile_image : this.form.profile_image;
                return profile_image;
            },
            updateInfo() {
                this.$Progress.start();
                if (this.form.password == '') {
                    this.form.password = undefined;
                }
                swal.fire({
                    title: 'Are you sure?',
                    text: "You want to update this information!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, update it!'
                }).then((result) => {
                    if (result.value) {
                        this.form.put('api/profile').then(() => {
                            Fire.$emit('AfterCreate');
                            swal.fire('Success!', 'Your Information was updated successfully !!', 'success',);
                            this.$Progress.finish();
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "error");
                        });
                    }
                }).catch((error) => {
                    swal.fire("Failed!", "Something went wrong!!", "error");
                })
            },
            updateProfile(e) {
                let extensions = ["image/png", "image/jpg", "image/jpeg", "image/JPEG", "image/JPG", "image/PNG"];
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (extensions.includes(file['type'])) {
                    if (file['size'] > limit) {
                        swal.fire('Error!', 'Image size should be less than 2MB !!', 'error',);
                        return false;
                    }

                } else {
                    swal.fire('Error!', 'Only is PNG, JPG and JPEG images are allowed !!', 'error',);
                    return false;
                }
                reader.readAsDataURL(file);
                reader.onloadend = (file) => {
                    let image = new Image();
                    image.src = file.target.result;
                    image.onload = function () {
                        var height = this.height;
                        var width = this.width;
                        if (height != width) {
                            swal.fire('Warning!!', 'Kindly upload a Square Size (512 X 512) Image for a Better Experience!!', 'warning',);
                            return false;
                        }
                    }
                    this.form.profile_image = reader.result;
                }

            },
            updateLogo(e) {
                let extensions = ["image/png", "image/jpg", "image/jpeg", "image/JPEG", "image/JPG", "image/PNG"];
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (extensions.includes(file['type'])) {
                    if (file['size'] > limit) {
                        swal.fire('Error!', 'Image size should be less than 2MB !!', 'error',);
                        return false;
                    }

                } else {
                    swal.fire('Error!', 'Only is PNG, JPG and JPEG images are allowed !!', 'error',);
                    return false;
                }
                reader.readAsDataURL(file);
                reader.onloadend = (file) => {
                    let image = new Image();
                    image.src = file.target.result;
                    image.onload = function () {
                        var height = this.height;
                        var width = this.width;
                        if (height != width) {
                            swal.fire('Warning!!', 'Kindly upload a Square Size (512 X 512) Image for a Better Experience!!', 'warning',);
                            return false;
                        }
                    }
                    this.appFrom.app_logo = reader.result;
                }

            },
            updateAppInfo() {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You want change your app settings!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, change it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.appFrom.put('api/appSetting/' + this.appFrom.id).then(() => {
                            Fire.$emit('AfterCreate');
                            swal.fire('Success!', 'App Setting Information was updated successfully !!', 'success',);
                            this.$Progress.finish();
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "error");
                        });
                    }
                }).catch((error) => {
                    swal.fire("Failed!", "Something went wrong!!", "error");
                })
            }
        },
        created() {
            axios.get('api/profile').then(({data}) => (this.form.fill(data.data)));
            if (this.$Gate.isAdmin()) {
                axios.get('api/appSetting').then(({data}) => (this.appFrom.fill(data)));
            }
        }
    }
</script>
