<template>
    <div v-if="displayData">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"><i class="fas fa-copy fa-fw"></i> Pages</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/posts">Pages</router-link>
                            </li>
                            <li v-show="!editmode" class="breadcrumb-item active">Add New Page</li>
                            <li v-show="editmode" class="breadcrumb-item active">Edit Page</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">

                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <h3 v-show="!editmode" class="card-title"><i class="fas fa-copy fa-fw text-green"></i>
                                    Add Page</h3>
                                <h3 v-show="editmode" class="card-title"><i class="fas fa-copy fa-fw text-green"></i>
                                    Edit Page</h3>

                                <div class="card-tools">

                                </div>
                            </div>
                            <form role="form" @submit.prevent="!editmode ? createPost() : editPost()">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <input v-model="form.title" type="text" class="form-control" name="title"
                                               id="title"
                                               placeholder="Enter Page Title" @keyup="titleSlug"
                                               :class="{ 'is-invalid': form.errors.has('title') }">
                                        <has-error :form="form" field="title"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label for="slug">Slug</label>
                                        <input v-model="form.slug" type="text"
                                               class="form-control" name="slug"
                                               id="slug" placeholder="Page Slug"
                                               :class="{ 'is-invalid': form.errors.has('slug') }">
                                        <has-error :form="form" field="slug"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label>Excerpt</label>
                                        <textarea v-model="form.excerpt" class="form-control" rows="3"
                                                  placeholder="Enter ..."
                                                  :class="{ 'is-invalid': form.errors.has('excerpt') }"></textarea>
                                        <has-error :form="form" field="excerpt"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label :class="{ 'is-invalid': form.errors.has('body') }">Post Body</label>
                                        <vue-editor v-model="form.body"></vue-editor>
                                        <has-error :form="form" field="body"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label for="featuredImage">Featured Image</label>
                                        <div class="input-group">
                                            <input type="file" @change="updateFeaturedImage"
                                                   class="form-control-file" id="featuredImage">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="seoTitle">SEO Title</label>
                                        <input v-model="form.seo_title" type="text" class="form-control"
                                               id="seoTitle" placeholder="SEO Title">
                                    </div>
                                    <div class="form-group">
                                        <label>Meta Description</label>
                                        <textarea v-model="form.meta_description" class="form-control" rows="3"
                                                  placeholder="Enter ..."></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label for="seoKeywords">Meta Keywords</label>
                                        <input v-model="form.meta_keywords" type="text" class="form-control"
                                               id="seoKeywords"
                                               placeholder="Meta Keywords">
                                    </div>
                                    <div class="form-group">
                                        <label for="page_type">Page Type</label>
                                        <select v-model="form.page_type" name="page_type" id="page_type"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('page_type') }">
                                            <option value="" selected="true" disabled="disabled">--Select Page Type--
                                            </option>
                                            <option value="HOMEPAGE">HOMEPAGE</option>
                                            <option value="ABOUTPAGE">ABOUTPAGE</option>
                                            <option value="CONTACTPAGE">CONTACTPAGE</option>
                                            <option value="BLOGPAGE">BLOGPAGE</option>
                                            <option value="CABSLISTSPAGE">CABSLISTSPAGE</option>
                                            <option value="TERMSANDCONDITIONPAGE">TERMSANDCONDITIONPAGE</option>
                                            <option value="PRIVACYPOLICYPAGE">PRIVACYPOLICYPAGE</option>
                                            <option value="BOOKTAXIPAGE">BOOKTAXIPAGE</option>
                                        </select>
                                        <has-error :form="form" field="page_type"></has-error>
                                    </div>
                                </div>

                                <!-- /.card-body -->

                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <NotFound/>
    </div>
</template>

<script>
    import {VueEditor} from "vue2-editor";
    import NotFound from "../../components/NotFound";

    export default {
        components: {
            VueEditor,
            NotFound
        },
        data() {
            return {
                editmode: false,
                displayData: false,
                postID: this.$route.params.id,
                form: new Form({
                    id: '',
                    title: '',
                    slug: '',
                    excerpt: '',
                    body: '',
                    image: '',
                    page_type: '',
                    seo_title: '',
                    meta_description: '',
                    meta_keywords: '',
                    is_featured: '',
                    status: '',
                })
            };
        },
        computed: {
            slug: function () {
                let slug = this.sanitizeTitle(this.form.title);
                return slug;
            }
        },
        methods: {
            sanitizeTitle: function (title) {
                let slug = "";
                // Change to lower case
                let titleLower = this.form.title.toLowerCase();
                // Letter "e"
                slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
                // Letter "a"
                slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
                // Letter "o"
                slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
                // Letter "u"
                slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
                // Letter "d"
                slug = slug.replace(/đ/gi, 'd');
                // Trim the last whitespace
                slug = slug.replace(/\s*$/g, '');
                // Change whitespace to "-"
                slug = slug.replace(/\s+/g, '-');
                // Trim the last special char
                slug = slug.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

                return slug;
            },
            titleSlug() {
                this.form.slug = this.slug;
            },
            getPost() {
                let base_url = window.location.origin + '/api/pages/';
                axios.get(base_url + this.postID).then((response) => {
                    if (response.data.status == 200) {
                        this.displayData = true;
                        this.form.fill(response.data.data);
                    } else {
                        this.$router.push('/not-found');
                    }
                });
            },
            updateFeaturedImage(e) {
                let extensions = ["image/png", "image/jpg", "image/jpeg", "image/JPEG", "image/JPG", "image/PNG"];
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (extensions.includes(file['type'])) {
                    if (file['size'] > limit) {
                        swal.fire('Error!', 'Image size should be less than 2MB !!', 'error',);
                        return false;
                    }

                } else {
                    swal.fire('Error!', 'Only is PNG, JPG and JPEG images are allowed !!', 'error',);
                    return false;
                }
                reader.readAsDataURL(file);
                reader.onloadend = (file) => {
                    let image = new Image();
                    image.src = file.target.result;
                    this.form.image = reader.result;
                }

            },
            createPost() {
                this.$Progress.start();
                this.form.post('api/pages')
                    .then(({data}) => {
                        Fire.$emit('AfterCreate');
                        toast.fire({
                            icon: 'success',
                            title: 'Page Created Successfully'
                        });
                        this.$router.push('/pages');
                        this.$Progress.finish();
                    }).catch((err) => {
                    this.$Progress.fail();
                    toast.fire({
                        icon: 'error',
                        title: 'Error Creating Page'
                    });
                });

            }, editPost() {
                let base_url = window.location.origin + '/api/pages/';
                this.$Progress.start();
                this.form.put(base_url + this.form.id).then(() => {
                    swal.fire(
                        'Updated!',
                        'Page was updated successfully.',
                        'success'
                    ).then((result) => {
                        toast.fire({
                            icon: 'success',
                            title: 'Page Updated Successfully'
                        });
                        this.$router.push('/pages');
                        this.$Progress.finish();
                    }).catch(() => {
                        this.$Progress.fail();
                        swal.fire("Failed!", "Something went wrong!!", "warning");
                    });
                    Fire.$emit('AfterCreate');
                }).catch(() => {
                    this.$Progress.fail();
                    swal.fire("Failed!", "Something went wrong!!", "warning");
                });
            }, deletePost(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.form.delete('api/pages/' + id).then(() => {
                            swal.fire(
                                'Deleted!',
                                'Page was deleted successfully.',
                                'success'
                            )
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    //console.log(error);
                })
            },
        },
        created() {
            let params = this.$route.params;
            if (Object.keys(params).length > 0) {
                this.displayData = false;
                let id = this.$route.params.id;

                if (id !== '' || id !== null) {
                    this.editmode = true;
                    this.getPost();
                }
            } else {
                this.displayData = true;
            }
        }
    }
</script>
