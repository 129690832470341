export default {
    methods: {
        classQueryStatus(status) {
            let textStyle;
            if (status == "OPEN") {
                textStyle = "badge badge-pill badge-primary";
            } else if (status == "INPROGRESS") {
                textStyle = "badge badge-pill badge-info";
            } else if (status == "SHIPPED") {
                textStyle = "badge badge-pill badge-warning";
            } else if (status == "CLOSE") {
                textStyle = "badge badge-pill badge-success";
            } else {
                textStyle = "badge badge-pill badge-danger";
            }
            return textStyle;
        },
    }
}
