<template>
    <div v-if="displayData">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"><i class="fas fa-taxi fa-fw"></i> Drivers</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/posts">Drivers</router-link>
                            </li>
                            <li v-show="!editmode" class="breadcrumb-item active">Add New Driver</li>
                            <li v-show="editmode" class="breadcrumb-item active">Edit Driver</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">

                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <h3 v-show="!editmode" class="card-title"><i class="fas fa-copy fa-fw text-green"></i>
                                    Add Driver</h3>
                                <h3 v-show="editmode" class="card-title"><i class="fas fa-copy fa-fw text-green"></i>
                                    Edit Driver</h3>

                                <div class="card-tools">

                                </div>
                            </div>
                            <form role="form" @submit.prevent="!editmode ? createPost() : editPost()">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="name">Full Name</label>
                                        <input v-model="form.name" type="text" class="form-control" name="name"
                                               id="name"
                                               placeholder="Full Name"
                                               :class="{ 'is-invalid': form.errors.has('name') }"
                                               @keypress="form.errors.clear('name')">
                                        <has-error :form="form" field="name"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input v-model="form.email" type="text"
                                               class="form-control" name="email"
                                               id="email" placeholder="Email"
                                               :class="{ 'is-invalid': form.errors.has('email') }"
                                               @keypress="form.errors.clear('email')">
                                        <has-error :form="form" field="email"></has-error>
                                    </div>

                                    <div class="form-group">
                                        <label for="profileImage">Driver Profile Image</label>
                                        <div class="input-group">
                                            <input type="file" name="profile_image" @change="updateImage"
                                                   class="form-control-file" id="profileImage">
                                            <span v-if="form.errors.has('profile_image')"
                                                  class="text-danger pt-1 text-xs">Please Upload Drivers Image</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="phone">Phone</label>
                                        <input v-model="form.phone" type="text" class="form-control"
                                               id="phone"
                                               placeholder="Phone"
                                               :class="{ 'is-invalid': form.errors.has('phone') }"
                                               @keypress="form.errors.clear('phone')">
                                        <has-error :form="form" field="phone"></has-error>
                                    </div>

                                    <div class="form-group">
                                        <label for="cab">Select Cab</label>
                                        <select id="cab" class="form-control" v-model="form.cab_id"
                                                :class="{ 'is-invalid': form.errors.has('cab_id') }"
                                                @change="form.errors.clear('cab_id')">
                                            <option selected disabled>-- Select One --</option>
                                            <option v-for="cab in cabs" :value="cab.id">{{cab.title}}</option>
                                        </select>
                                        <has-error :form="form" field="cab_id"></has-error>
                                    </div>

                                    <div class="form-group">
                                        <label>Address</label>
                                        <textarea v-model="form.address" class="form-control" rows="3"
                                                  placeholder="Enter ..."
                                                  :class="{ 'is-invalid': form.errors.has('address') }"
                                                  @keypress="form.errors.clear('address')"></textarea>
                                        <has-error :form="form" field="address"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label for="city">City</label>
                                        <input v-model="form.city" type="text" class="form-control"
                                               id="city" placeholder="City"
                                               :class="{ 'is-invalid': form.errors.has('city') }"
                                               @keypress="form.errors.clear('city')">
                                        <has-error :form="form" field="city"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label for="state">State</label>
                                        <input v-model="form.state" type="text" class="form-control"
                                               id="state" placeholder="State"
                                               :class="{ 'is-invalid': form.errors.has('state') }"
                                               @keypress="form.errors.clear('state')">
                                        <has-error :form="form" field="state"></has-error>
                                    </div>

                                    <div class="form-group">
                                        <label for="rcnumber">RC Number</label>
                                        <input v-model="form.rc_number" type="text" class="form-control"
                                               id="rcnumber" placeholder="RC Number"
                                               :class="{ 'is-invalid': form.errors.has('rc_number') }"
                                               @keypress="form.errors.clear('rc_number')">
                                        <has-error :form="form" field="rc_number"></has-error>
                                    </div>

                                    <div class="form-group">
                                        <label for="rcImage">RC Image</label>
                                        <div class="input-group">
                                            <input type="file" @change="updateImage" name="rc_image"
                                                   class="form-control-file" id="rcImage">
                                            <span v-if="form.errors.has('rc_image')" class="text-danger pt-1 text-xs">Please Upload RC Image</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="dlnumber">DL Number</label>
                                        <input v-model="form.dl_number" type="text" class="form-control"
                                               id="dlnumber" placeholder="DL Number"
                                               :class="{ 'is-invalid': form.errors.has('dl_number') }"
                                               @keypress="form.errors.clear('dl_number')">
                                        <has-error :form="form" field="dl_number"></has-error>
                                    </div>

                                    <div class="form-group">
                                        <label for="dlImage">DL Image</label>
                                        <div class="input-group">
                                            <input type="file" @change="updateImage" name="dl_image"
                                                   class="form-control-file" id="dlImage">
                                            <span v-if="form.errors.has('dl_image')" class="text-danger pt-1 text-xs">Please Upload Driving Licence Image</span>
                                        </div>
                                    </div>


                                </div>

                                <!-- /.card-body -->

                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <NotFound/>
    </div>
</template>

<script>
    import {VueEditor} from "vue2-editor";
    import NotFound from "../../components/NotFound";

    export default {
        components: {
            VueEditor,
            NotFound
        },
        data() {
            return {
                editmode: false,
                displayData: false,
                postID: this.$route.params.id,
                cabs: {},
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    profile_image: '',
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                    cab_id: '',
                    rc_number: '',
                    rc_image: '',
                    dl_number: '',
                    dl_image: ''
                })
            };
        },
        methods: {
            loadCabs() {
                let base_url = window.location.origin + '/api/cabs/';
                axios.get(base_url).then((response) => {
                    if (response.data.status == 200) {
                        this.displayData = true;
                        this.cabs = response.data.data;
                    }
                });
            },
            getPost() {
                let base_url = window.location.origin + '/api/drivers/';
                axios.get(base_url + this.postID).then((response) => {
                    if (response.data.status == 200) {
                        this.displayData = true;
                        this.form.fill(response.data.data);
                    }
                });
            },
            updateImage(e) {
                let extensions = ["image/png", "image/jpg", "image/jpeg", "image/JPEG", "image/JPG", "image/PNG"];
                let field = e.target.name;
                this.form.errors.clear(field);
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (extensions.includes(file['type'])) {
                    if (file['size'] > limit) {
                        swal.fire('Error!', 'Image size should be less than 2MB !!', 'error',);
                        return false;
                    }

                } else {
                    swal.fire('Error!', 'Only is PNG, JPG and JPEG images are allowed !!', 'error',);
                    return false;
                }
                reader.readAsDataURL(file);
                reader.onloadend = (file) => {
                    let image = new Image();
                    image.src = file.target.result;
                    if (field == 'profile_image') {
                        this.form.profile_image = reader.result;
                    } else if (field == 'rc_image') {
                        this.form.rc_image = reader.result;
                    } else if (field == 'dl_image') {
                        this.form.dl_image = reader.result;
                    }
                }

            },
            createPost() {
                this.$Progress.start();
                this.form.post('api/drivers')
                    .then(({data}) => {
                        Fire.$emit('AfterCreate');
                        toast.fire({
                            icon: 'success',
                            title: 'Post Created Successfully'
                        });
                        this.$router.push('/drivers');
                        this.$Progress.finish();
                    }).catch((err) => {
                    console.log(err);
                });

            }, editPost() {
                let base_url = window.location.origin + '/api/posts/';
                this.$Progress.start();
                this.form.put(base_url + this.form.id).then(() => {
                    swal.fire(
                        'Updated!',
                        'Post was updated successfully.',
                        'success'
                    ).then((result) => {
                        toast.fire({
                            icon: 'success',
                            title: 'Post Updated Successfully'
                        });
                        this.$router.push('/posts');
                        this.$Progress.finish();
                    }).catch(() => {
                        this.$Progress.fail();
                        swal.fire("Failed!", "Something went wrong!!", "warning");
                    });
                    Fire.$emit('AfterCreate');
                }).catch(() => {
                    this.$Progress.fail();
                    swal.fire("Failed!", "Something went wrong!!", "warning");
                });
            }, deletePost(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.form.delete('api/posts/' + id).then(() => {
                            swal.fire(
                                'Deleted!',
                                'Post was deleted successfully.',
                                'success'
                            )
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    //console.log(error);
                })
            },
        },
        created() {
            let params = this.$route.params;
            this.loadCabs();
            if (Object.keys(params).length > 0) {
                this.displayData = false;
                let id = this.$route.params.id;

                if (id !== '' || id !== null) {
                    this.editmode = true;
                    this.getPost();
                }
            } else {
                this.displayData = true;
            }
        }
    }
</script>
