<style scoped>
    .action-btn {
        background: transparent;
        border: none;
    }

    #userViewModal .card-header {
        border-radius: 0 !important;
        max-height: 0px;
        padding: 0px;
        margin: 0px;
        border: none;
    }

    #userViewModal .btn-tool {
        cursor: pointer !important;
    }
</style>

<template>
    <div>
        <div v-if="$Gate.isAdmin()">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark"><i class="fas fa-users-cog fa-fw"></i> Users</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item">
                                    <router-link to="/dashboard">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active">Users</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <h3 class="card-title"><i class="fas fa-users fa-fw text-green"></i> Users</h3>

                                    <div class="card-tools">
                                        <router-link type="button" class="btn btn-success" to="/add-driver"><i
                                            class="fas fa-user-plus fa-fw"></i>
                                            Add
                                            User
                                        </router-link>
                                    </div>
                                </div>
                                <div class="card-body table-responsive p-0">
                                    <table class="table table-hover">
                                        <thead class="text-center text-blue">
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Profile Image</th>
                                            <th>Status</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody class="text-center">
                                        <tr v-if="status == $root.statusNOK">
                                            <td colspan="8">{{message}}</td>
                                        </tr>
                                        <tr v-for="(user,index) in users.data" :key="user.id">
                                            <td>{{index+1}}</td>
                                            <td>{{user.name | upText}}</td>
                                            <td>{{user.email}}</td>
                                            <td>{{user.phone}}</td>
                                            <td><img :src="user.profile_image" alt="" height="40" width="40"/></td>
                                            <td>{{user.status}}</td>
                                            <td>{{user.created_at | createDate}}</td>
                                            <td>
                                                <button class="action-btn" @click="viewUser(user.id)"><i
                                                    class="fas fa-eye fa-fw text-green"></i></button>
                                                | <a href="#"
                                                     @click="editUser(user.id)"><i
                                                class="fas fa-edit fa-fw text-orange"></i></a> | <a href="#"
                                                                                                    @click="deleteUser(user.id)"><i
                                                class="fas fa-trash fa-fw text-red"></i></a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer cus-pagination">
                                    <pagination :data="users" @pagination-change-page="getResults">
                                        <span slot="prev-nav">&lt; Previous</span>
                                        <span slot="next-nav">Next &gt;</span>
                                    </pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <not-found></not-found>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                users: {},
                status: '',
                message: '',
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    profile_image: '',
                    phone: '',
                    status: ''
                })
            }
        },
        methods: {
            viewUser(id) {
                this.$router.push();
            },
            editUser(id) {
                this.$Progress.start();
                this.$Progress.finish();
            },
            deleteUser(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        this.form.delete('api/users/' + id).then(() => {
                            swal.fire(
                                'Deleted!',
                                'User was deleted successfully.',
                                'success'
                            )
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    //console.log(error);
                })
            },
            getResults(page = 1) {
                axios.get('api/drivers?page=' + page)
                    .then(response => {
                        this.users = response.data;
                    });
            },
            loadUsers() {
                if (this.$Gate.isAdmin()) {
                    this.$Progress.start();
                    axios.get('api/drivers').then(({data}) => {
                        if (data.status == this.$root.statusOK) {
                            this.status = data.status;
                            this.users = data.data;
                        } else {
                            this.status = data.status;
                            this.message = data.message;
                        }
                    });
                    this.$Progress.finish();
                }
            }
        },
        created() {
            Fire.$on('searching', () => {
                let query = this.$root.search;
                axios.get('api/findDriver?q=' + query).then((data) => {
                    this.$Progress.start();
                    this.users = data.data
                    this.$Progress.finish();
                }).catch(() => {
                    this.$Progress.fail();
                    swal.fire("Failed!", "Something went wrong, try again later!!", "warning");
                });
            });
            this.loadUsers();
            Fire.$on('AfterCreate', () => {
                this.loadUsers();
            });
        }
    }
</script>
