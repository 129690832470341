<template>
    <div>
        <div v-if="$Gate.isAdmin()">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark"><i class="fas fa-taxi fa-fw"></i> Cab Segments</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item">
                                    <router-link to="/dashboard">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active">Cab Segments</li>
                            </ol>
                        </div>
                    </div>
                    <div class="row" v-if="loading">
                        <div class="col-12 mr-auto ml-auto">
                            <p class="text-center">Loading..</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <h3 class="card-title"><i class="fas fa-taxi fa-fw text-green"></i> Cab Segments
                                    </h3>

                                    <div class="card-tools">
                                        <router-link to="/add-cab-segments" class="btn btn-success"><i
                                            class="fas fa-plus-circle fa-fw"></i>
                                            Add Cab Segment
                                        </router-link>
                                    </div>
                                </div>
                                <div class="card-body table-responsive p-0">
                                    <table class="table table-hover">
                                        <thead class="text-center text-blue">
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Segment Name</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody class="text-center">
                                        <tr v-if="status == $root.statusNOK">
                                            <td colspan="4">{{message}}</td>
                                        </tr>
                                        <tr v-for="(item,index) in items.data" :key="item.id" v-else>
                                            <td>{{index+1}}</td>
                                            <td>{{item.segment_name}}</td>
                                            <td>{{item.created_at | createDate}}</td>
                                            <td>
                                                <router-link :to="'/view-cab-segments/' + item.id"><i
                                                    class="fas fa-eye fa-fw text-green"></i></router-link>
                                                |
                                                <router-link :to="'/edit-cab-segments/' + item.id"><i
                                                    class="fas fa-edit fa-fw text-orange"></i></router-link>
                                                | <a href="javascript:void(0)" @click="deleteData(item.id)"><i
                                                class="fas fa-trash fa-fw text-red"></i></a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer cus-pagination">
                                    <pagination :data="items" @pagination-change-page="getResults">
                                        <span slot="prev-nav">&lt; Previous</span>
                                        <span slot="next-nav">Next &gt;</span>
                                    </pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <not-found></not-found>
        </div>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                items: {},
                status: '',
                message: '',
                loading: false
            }
        },
        methods: {
            deleteData(id) {

                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        axios.delete('api/cabSegments/' + id).then(({data}) => {
                            if (data.status == this.$root.statusOK) {
                                swal.fire(
                                    'Deleted!',
                                    'Segment was deleted successfully.',
                                    'success'
                                );
                                this.$Progress.finish();
                                Fire.$emit('AfterCreate');
                            } else {
                                this.$Progress.fail();
                                swal.fire("Failed!", data.message, "warning");
                            }
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    swal.fire("Failed!", "Something went wrong!!", "warning");
                })
            },
            getResults(page = 1) {
                axios.get('api/cabSegments?page=' + page)
                    .then(response => {
                        this.items = response.data;
                    });
            },
            loadData() {
                if (this.$Gate.isAdmin()) {
                    this.$Progress.start();
                    axios.get('api/cabSegments').then(({data}) => {
                        if (data.status == this.$root.statusOK) {
                            this.status = data.status;
                            this.items = data;
                        } else {
                            this.status = data.status;
                            this.message = data.message;
                        }
                    });
                    this.$Progress.finish();
                }
            },
        }, created() {
            Fire.$on('searching', () => {
                let query = this.$parent.$parent.search;
                this.loading = true;
                axios.get('api/findSegment?q=' + query).then((data) => {
                    this.$Progress.start();
                    if (data.data.status == this.$root.statusOK) {
                        this.loading = false;
                        this.status = data.data.status;
                        this.items = data.data
                    } else {
                        this.loading = false;
                        this.status = data.data.status;
                        this.message = data.data.message;
                    }
                    this.$Progress.finish();
                }).catch(() => {
                    this.$Progress.fail();
                    this.loading = false;
                    swal.fire("Failed!", "Something went wrong, try again later!!", "warning");
                });
            });
            this.loadData();
            Fire.$on('AfterCreate', () => {
                this.loadData();
            });
        },
    };
</script>
