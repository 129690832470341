<template>
    <div>
        <div v-if="$Gate.isAdmin() || $Gate.isManager()">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark"><i class="fas fa-taxi fa-fw"></i> Queries</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item">
                                    <router-link to="/dashboard">Home</router-link>
                                </li>
                                <li class="breadcrumb-item active">Queries</li>
                            </ol>
                        </div>
                    </div>
                    <div class="row text-center" v-if="loading">
                        <div class="col-12 ml-auto mr-auto">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">

                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <h3 class="card-title"><i class="fas fa-taxi fa-fw text-green"></i>Queries</h3>
                                    <div class="card-tools">
                                        <button @click="download" class="btn btn-success"><i
                                            class="fas fa-download fa-fw"></i>
                                            Download Report
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body table-responsive p-0">
                                    <table class="table table-hover">
                                        <thead class="text-center text-blue">
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Name</th>
                                            <th>Phone</th>
                                            <th>Pickup Date</th>
                                            <th>Pickup Location</th>
                                            <th>Traveller Count</th>
                                            <th>Query Status</th>
                                            <th>Created At</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody class="text-center">
                                        <tr v-if="status == $root.statusNOK">
                                            <td colspan="9">{{message}}</td>
                                        </tr>
                                        <tr v-for="(item,index) in items.data" :key="item.id" v-else>
                                            <td>{{index+1}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{item.phone}}</td>
                                            <td>{{item.pickup_date}}</td>
                                            <td>{{item.pickup_location}}</td>
                                            <td>{{item.traveller_count}}</td>
                                            <td>
                                                <b :class="classQueryStatus(item.query_status)">{{item.query_status}}</b>
                                            </td>
                                            <td>{{item.created_at | createDate}}</td>
                                            <td>
                                                <a class="action-buttons" href="javascript:void(0)"
                                                   @click="updateData(item.id,'complete')"
                                                   v-if="item.query_status == 'OPEN'" v-tooltip:top="'Mark Complete'"><i
                                                    class="fas fa-check-circle fa-fw text-green"></i></a>

                                                <a class="action-buttons" href="javascript:void(0)"
                                                   @click="updateData(item.id,'cancel')"
                                                   v-if="item.query_status == 'OPEN'" v-tooltip:top="'Cancel Request'"><i
                                                    class="fas fa-times-circle fa-fw text-orange"></i></a>
                                                <a href="javascript:void(0)" @click="deleteData(item.id)"><i
                                                    class="fas fa-trash fa-fw text-red" v-tooltip:top="'Delete Request'"></i></a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer cus-pagination">
                                    <pagination :data="items" @pagination-change-page="getResults">
                                        <span slot="prev-nav">&lt; Previous</span>
                                        <span slot="next-nav">Next &gt;</span>
                                    </pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <not-found></not-found>
        </div>
    </div>
</template>

<script>
    import styleMixins from "../../mixins/styleMixins";

    export default {

        data() {
            return {
                items: {},
                status: '',
                message: '',
                loading: true
            }
        },
        methods: {
            download() {
                axios.get('api/downloadReport', {responseType: 'blob',}).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'query-report.pdf');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
            },
            deleteData(id) {

                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        axios.delete('api/cabQueries/' + id).then(() => {
                            swal.fire(
                                'Deleted!',
                                'Data was deleted successfully.',
                                'success'
                            );
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    swal.fire("Failed!", "Something went wrong!!", "warning");
                })
            },
            updateData(id, action) {
                let textConfirm = "Complete";
                if (action == "cancel") {
                    textConfirm = "Cancel";
                }
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, ' + textConfirm + ' it!'
                }).then((result) => {
                    if (result.value) {
                        this.$Progress.start();
                        axios.patch('api/cabQueries/' + id, {query_status: action}).then(() => {
                            swal.fire(
                                'Updated!',
                                'Status was deleted successfully.',
                                'success'
                            );
                            this.$Progress.finish();
                            Fire.$emit('AfterCreate');
                        }).catch(() => {
                            this.$Progress.fail();
                            swal.fire("Failed!", "Something went wrong!!", "warning");
                        });
                    }
                }).catch((error) => {
                    swal.fire("Failed!", "Something went wrong!!", "warning");
                })
            },
            getResults(page = 1) {
                axios.get('api/cabQueries?page=' + page)
                    .then(response => {
                        this.items = response.data;
                    });
            },
            loadData() {
                if (this.$Gate.isAdmin() || this.$Gate.isManager()) {
                    this.$Progress.start();
                    axios.get('api/cabQueries').then(({data}) => {
                        this.loading = false;
                        if (data.status == this.$root.statusOK) {
                            this.status = data.status;
                            this.items = data;
                        } else {
                            this.status = data.status;
                            this.message = data.message;
                        }
                    });
                    this.$Progress.finish();
                }
            },
        },
        mixins: [
            styleMixins
        ],
        created() {
            Fire.$on('searching', () => {
                let query = this.$root.search;
                this.loading = true;
                axios.get('api/findQuery?q=' + query).then((data) => {
                    this.$Progress.start();
                    if (data.data.status == this.$root.statusOK) {
                        this.loading = false;
                        this.status = data.data.status;
                        this.items = data.data
                    } else {
                        this.loading = false;
                        this.status = data.data.status;
                        this.message = data.data.message;
                    }
                    this.$Progress.finish();
                }).catch(() => {
                    this.$Progress.fail();
                    this.loading = false;
                    swal.fire("Failed!", "Something went wrong, try again later!!", "warning");
                });
            });
            this.loadData();
            Fire.$on('AfterCreate', () => {
                this.loadData();
            });
        },
    };
</script>

<style scoped>
    .action-buttons {
        border-right: 1.5px solid black;
        padding-right: 2px;
    }
</style>
